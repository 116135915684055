import React from 'react';
import { Box, Typography } from '@mui/material';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import TrendingUpIcon from '@mui/icons-material/TrendingUp';

const StatisticItem = ({ icon, title, value }) => (
  <Box sx={{ display: 'flex', alignItems: 'center', mr: 4 }}>
    <Box sx={{ position: 'relative', display: 'inline-flex', mr: 1 }}>
      {React.cloneElement(icon, { sx: { fontSize: 20, color: 'primary.main' } })}
    </Box>
    <Box>
      <Typography variant="caption" display="block" color="text.secondary">
        {title}
      </Typography>
      <Typography variant="body2" fontWeight="medium">
        {value}
      </Typography>
    </Box>
  </Box>
);

const StatisticsBanner = () => {
  // Sample data - replace with actual data from your state management
  const stats = {
    today: { completed: 3, goal: 8 },
    week: { completed: 15, goal: 40 },
    streak: 5,
    focusScore: 85,
  };

  return (
    <Box sx={{ display: 'flex', alignItems: 'center' }}>
      <StatisticItem
        icon={<AccessTimeIcon />}
        title="Today's Progress"
        value={`${stats.today.completed}/${stats.today.goal}`}
      />
      <StatisticItem
        icon={<CheckCircleIcon />}
        title="Tasks Completed"
        value={`${stats.week.completed}/${stats.week.goal}`}
      />
      <StatisticItem
        icon={<TrendingUpIcon />}
        title="Streak"
        value={`${stats.streak} days`}
      />
      <StatisticItem
        icon={<TrendingUpIcon />}
        title="Focus Score"
        value={`${stats.focusScore}%`}
      />
    </Box>
  );
};

export default StatisticsBanner;
