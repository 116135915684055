import { API_URL } from '../config/api';

const delay = ms => new Promise(resolve => setTimeout(resolve, ms));

export const getTasks = async (userId) => {
  let attempts = 0;
  const maxAttempts = 3;
  const baseDelay = 1000; // Start with 1 second delay

  while (attempts < maxAttempts) {
    try {
      console.log(`Fetching tasks for user ${userId}, attempt ${attempts + 1}`);
      
      const response = await fetch(`${API_URL}/api/tasks/${userId}`);
      
      if (!response.ok) {
        const errorData = await response.json().catch(() => null);
        throw new Error(errorData?.message || `HTTP error! status: ${response.status}`);
      }
      
      const data = await response.json();
      console.log('Successfully fetched tasks:', data);
      return data;
    } catch (error) {
      attempts++;
      console.error(`Error fetching tasks (attempt ${attempts}):`, error);
      
      if (attempts === maxAttempts) {
        throw new Error(`Failed to fetch tasks after ${maxAttempts} attempts: ${error.message}`);
      }
      
      // Exponential backoff
      const waitTime = baseDelay * Math.pow(2, attempts - 1);
      console.log(`Waiting ${waitTime}ms before retry...`);
      await delay(waitTime);
    }
  }
};

export const createTask = async (taskData) => {
  let attempts = 0;
  const maxAttempts = 3;
  const baseDelay = 1000; // Start with 1 second delay

  while (attempts < maxAttempts) {
    try {
      if (!taskData.userId) {
        throw new Error('userId is required to create a task');
      }

      console.log('Creating task with data:', taskData);
      console.log('API URL:', API_URL);

      const response = await fetch(`${API_URL}/api/tasks`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Accept': 'application/json'
        },
        body: JSON.stringify(taskData),
      });

      if (!response.ok) {
        const errorData = await response.json().catch(() => null);
        throw new Error(errorData?.message || `HTTP error! status: ${response.status}`);
      }

      const data = await response.json();
      console.log('Successfully created task:', data);
      return data;
    } catch (error) {
      attempts++;
      console.error(`Error in createTask (attempt ${attempts}):`, {
        error,
        message: error.message,
        stack: error.stack
      });
      
      if (attempts === maxAttempts) {
        throw new Error(`Failed to create task after ${maxAttempts} attempts: ${error.message}`);
      }
      
      // Exponential backoff
      const waitTime = baseDelay * Math.pow(2, attempts - 1);
      console.log(`Waiting ${waitTime}ms before retry...`);
      await delay(waitTime);
    }
  }
};

export const updateTask = async (taskId, taskData) => {
  let attempts = 0;
  const maxAttempts = 3;
  const baseDelay = 1000; // Start with 1 second delay

  while (attempts < maxAttempts) {
    try {
      console.log(`Updating task ${taskId} with data:`, taskData);
      
      const response = await fetch(`${API_URL}/api/tasks/${taskId}`, {
        method: 'PATCH',
        headers: {
          'Content-Type': 'application/json',
          'Accept': 'application/json'
        },
        body: JSON.stringify(taskData),
      });

      if (!response.ok) {
        const errorData = await response.json().catch(() => null);
        throw new Error(errorData?.message || `HTTP error! status: ${response.status}`);
      }

      const data = await response.json();
      console.log('Successfully updated task:', data);
      return data;
    } catch (error) {
      attempts++;
      console.error(`Error updating task (attempt ${attempts}):`, error);
      
      if (attempts === maxAttempts) {
        throw new Error(`Failed to update task after ${maxAttempts} attempts: ${error.message}`);
      }
      
      // Exponential backoff
      const waitTime = baseDelay * Math.pow(2, attempts - 1);
      console.log(`Waiting ${waitTime}ms before retry...`);
      await delay(waitTime);
    }
  }
};

export const deleteTask = async (taskId) => {
  let attempts = 0;
  const maxAttempts = 3;
  const baseDelay = 1000; // Start with 1 second delay

  while (attempts < maxAttempts) {
    try {
      console.log(`Deleting task ${taskId}`);
      
      const response = await fetch(`${API_URL}/api/tasks/${taskId}`, {
        method: 'DELETE',
        headers: {
          'Accept': 'application/json'
        }
      });

      if (!response.ok) {
        const errorData = await response.json().catch(() => null);
        throw new Error(errorData?.message || `HTTP error! status: ${response.status}`);
      }

      const data = await response.json();
      console.log('Successfully deleted task:', data);
      return data;
    } catch (error) {
      attempts++;
      console.error(`Error deleting task (attempt ${attempts}):`, error);
      
      if (attempts === maxAttempts) {
        throw new Error(`Failed to delete task after ${maxAttempts} attempts: ${error.message}`);
      }
      
      // Exponential backoff
      const waitTime = baseDelay * Math.pow(2, attempts - 1);
      console.log(`Waiting ${waitTime}ms before retry...`);
      await delay(waitTime);
    }
  }
};
