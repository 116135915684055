import React, { useState, useEffect, useCallback } from 'react';
import { DragDropContext } from '@hello-pangea/dnd';
import { Box, Paper, Button, Typography, styled } from '@mui/material';
import TaskColumn from './TaskColumn';
import { createTask, updateTask, deleteTask } from '../../services/taskService';

const COLUMN_IDS = {
  TODO: 'todo',
  IN_PROGRESS: 'inProgress',
  DONE: 'done'
};

const BoardContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  justifyContent: 'space-between',
  padding: theme.spacing(1),
  gap: theme.spacing(1),
  height: 'calc(100vh - 140px)',
  overflowX: 'auto',
  '& > *': {
    flex: 1,
    minWidth: '340px',
    maxWidth: 'calc(33.33% - 6px)'
  }
}));

const TimerContainer = styled(Box)(({ theme }) => ({
  position: 'fixed',
  bottom: 0,
  left: 0,
  right: 0,
  background: theme.palette.background.paper,
  padding: theme.spacing(1, 1.5),
  boxShadow: theme.shadows[1],
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  gap: theme.spacing(1.5),
  zIndex: 1000
}));

const Timer = styled(Typography)(({ theme }) => ({
  fontSize: '1.8rem',
  fontWeight: 500,
  color: theme.palette.primary.main,
  marginRight: theme.spacing(1)
}));

const TimerStats = styled(Box)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  gap: theme.spacing(0.75),
  fontSize: '0.875rem',
  color: theme.palette.text.secondary
}));

const PomodoroCount = styled('span')(({ theme }) => ({
  color: theme.palette.primary.main,
  fontWeight: 500
}));

const KanbanBoard = ({ user, tasks = [], onTasksChange }) => {
  const [isDragDisabled, setIsDragDisabled] = useState(false);
  const [activeTaskId, setActiveTaskId] = useState(null);
  const [pomodoroRunning, setPomodoroRunning] = useState(false);
  const [timeLeft, setTimeLeft] = useState(25 * 60); // 25 minutes in seconds
  const [totalPomodoros, setTotalPomodoros] = useState(0);
  const [sessionStartTime, setSessionStartTime] = useState(null);

  useEffect(() => {
    const timer = setTimeout(() => {
      setIsDragDisabled(false);
    }, 500);

    return () => clearTimeout(timer);
  }, []);

  const handleDragEnd = async (result) => {
    if (!result.destination || !user) return;

    const { source, destination, draggableId } = result;
    
    if (source.droppableId === destination.droppableId && 
        source.index === destination.index) {
      return;
    }

    // Get all tasks in source and destination columns
    const sourceColumnTasks = tasks
      .filter(t => t.status === source.droppableId)
      .sort((a, b) => (a.order || 0) - (b.order || 0));

    const destColumnTasks = source.droppableId === destination.droppableId 
      ? sourceColumnTasks 
      : tasks
          .filter(t => t.status === destination.droppableId)
          .sort((a, b) => (a.order || 0) - (b.order || 0));

    // Remove task from source
    const [movedTask] = sourceColumnTasks.splice(source.index, 1);
    movedTask.status = destination.droppableId;

    // Insert task at destination
    if (source.droppableId === destination.droppableId) {
      sourceColumnTasks.splice(destination.index, 0, movedTask);
      sourceColumnTasks.forEach((task, index) => {
        task.order = index;
      });
    } else {
      destColumnTasks.splice(destination.index, 0, movedTask);
      sourceColumnTasks.forEach((task, index) => {
        task.order = index;
      });
      destColumnTasks.forEach((task, index) => {
        task.order = index;
      });
    }

    // Create new tasks array with updated tasks
    const newTasks = tasks.filter(t => 
      t.status !== source.droppableId && 
      t.status !== destination.droppableId
    );

    if (source.droppableId === destination.droppableId) {
      newTasks.push(...sourceColumnTasks);
    } else {
      newTasks.push(...sourceColumnTasks, ...destColumnTasks);
    }

    // Optimistically update UI
    onTasksChange(newTasks);

    // Update in database
    try {
      await updateTask(draggableId, {
        status: destination.droppableId,
        order: destination.index
      });
    } catch (error) {
      console.error('Error updating task:', error);
      onTasksChange(tasks);
    }

    // Handle task completion
    if (destination.droppableId === COLUMN_IDS.DONE && 
        source.droppableId !== COLUMN_IDS.DONE) {
      // You can add any completion logic here
      console.log('Task completed:', draggableId);
    }
  };

  const handleTaskCreate = async (taskData) => {
    if (!user || !user.uid) {
      console.error('Cannot create task: No user logged in or invalid user ID');
      return;
    }
    
    const newTaskData = {
      ...taskData,
      userId: user.uid,
      title: taskData.title,
      content: taskData.title,
      description: taskData.description || '',
      status: COLUMN_IDS.TODO,
      pomodoro: taskData.pomodoro || 1,
      order: tasks.length > 0 ? Math.max(...tasks.map(t => t.order || 0)) + 1 : 0
    };
    
    console.log('Attempting to create task with data:', newTaskData);
    
    try {
      const newTask = await createTask(newTaskData);
      console.log('Successfully created task:', newTask);
      onTasksChange([...tasks, newTask]);
    } catch (error) {
      console.error('Error creating task:', error);
      if (error.response) {
        const errorData = await error.response.json();
        console.error('Server response:', errorData);
      }
    }
  };

  const handleTaskUpdate = async (taskId, taskData) => {
    if (!user) return;
    
    try {
      const updatedTask = await updateTask(taskId, taskData);
      onTasksChange(tasks.map(task => 
        task.id === taskId ? updatedTask : task
      ));
    } catch (error) {
      console.error('Error updating task:', error);
    }
  };

  const handleTaskDelete = async (taskId) => {
    if (!user) return;
    
    try {
      await deleteTask(taskId);
      onTasksChange(tasks.filter(task => task.id !== taskId));
    } catch (error) {
      console.error('Error deleting task:', error);
    }
  };

  const handleTaskClick = (taskId) => {
    setActiveTaskId(taskId);
    if (!pomodoroRunning) {
      setTimeLeft(25 * 60);
      setSessionStartTime(new Date());
    }
  };

  const startPomodoro = () => {
    setPomodoroRunning(true);
    setSessionStartTime(new Date());
  };

  const pausePomodoro = () => {
    setPomodoroRunning(false);
    setSessionStartTime(null);
  };

  useEffect(() => {
    let timer;
    if (pomodoroRunning && timeLeft > 0) {
      timer = setInterval(() => {
        setTimeLeft(prev => {
          if (prev <= 1) {
            setPomodoroRunning(false);
            setTotalPomodoros(prev => prev + 1);
            return 25 * 60;
          }
          return prev - 1;
        });
      }, 1000);
    }
    return () => clearInterval(timer);
  }, [pomodoroRunning]);

  const formatTime = (seconds) => {
    const minutes = Math.floor(seconds / 60);
    const remainingSeconds = seconds % 60;
    return `${minutes}:${remainingSeconds.toString().padStart(2, '0')}`;
  };

  const getColumnTasks = useCallback((status) => {
    return tasks
      .filter(task => task.status === status)
      .sort((a, b) => (a.order || 0) - (b.order || 0));
  }, [tasks]);

  const activeTask = tasks.find(t => t.id === activeTaskId);

  return (
    <Box sx={{ height: '100%', display: 'flex', flexDirection: 'column' }}>
      <Paper elevation={0} sx={{ bgcolor: 'background.default', p: 2, borderRadius: 2, mb: 8 }}>
        <DragDropContext onDragEnd={handleDragEnd}>
          <BoardContainer>
            <TaskColumn
              title="To Do"
              status={COLUMN_IDS.TODO}
              tasks={tasks.filter(task => task.status === COLUMN_IDS.TODO)}
              isDragDisabled={isDragDisabled}
              onTaskCreate={handleTaskCreate}
              onTaskUpdate={handleTaskUpdate}
              onTaskDelete={handleTaskDelete}
              onTaskClick={handleTaskClick}
              activeTaskId={activeTaskId}
            />
            <TaskColumn
              title="In Progress"
              status={COLUMN_IDS.IN_PROGRESS}
              tasks={tasks.filter(task => task.status === COLUMN_IDS.IN_PROGRESS)}
              isDragDisabled={isDragDisabled}
              onTaskCreate={handleTaskCreate}
              onTaskUpdate={handleTaskUpdate}
              onTaskDelete={handleTaskDelete}
              onTaskClick={handleTaskClick}
              activeTaskId={activeTaskId}
            />
            <TaskColumn
              title="Done"
              status={COLUMN_IDS.DONE}
              tasks={tasks.filter(task => task.status === COLUMN_IDS.DONE)}
              isDragDisabled={isDragDisabled}
              onTaskCreate={handleTaskCreate}
              onTaskUpdate={handleTaskUpdate}
              onTaskDelete={handleTaskDelete}
              onTaskClick={handleTaskClick}
              activeTaskId={activeTaskId}
            />
          </BoardContainer>
        </DragDropContext>
      </Paper>
      {activeTask && (
        <TimerContainer>
          <Timer variant="h4">{formatTime(timeLeft)}</Timer>
          <Button
            variant="contained"
            color="primary"
            onClick={pomodoroRunning ? pausePomodoro : startPomodoro}
          >
            {pomodoroRunning ? 'Pause' : (timeLeft === 25 * 60 ? 'Start' : 'Resume')}
          </Button>
          <Typography>
            Working on: <strong>{activeTask.title || activeTask.content}</strong>
          </Typography>
          <TimerStats>
            Today: <PomodoroCount>{totalPomodoros}</PomodoroCount> pomodoros
          </TimerStats>
        </TimerContainer>
      )}
    </Box>
  );
};

export default KanbanBoard;
