import React from 'react';
import { styled } from '@mui/material/styles';
import { Grid, Paper } from '@mui/material';
import KanbanBoard from '../../components/KanbanBoard/KanbanBoard';
import PomodoroTimer from '../../components/PomodoroTimer';

const Root = styled('div')(({ theme }) => ({
  flexGrow: 1,
  padding: theme.spacing(3),
}));

const StyledPaper = styled(Paper)(({ theme }) => ({
  padding: theme.spacing(2),
  height: '100%',
}));

const Dashboard = ({ user, tasks, onTasksChange }) => {
  return (
    <Root>
      <Grid container spacing={3}>
        <Grid item xs={12} md={3}>
          <StyledPaper>
            <PomodoroTimer />
          </StyledPaper>
        </Grid>
        <Grid item xs={12} md={9}>
          <StyledPaper>
            <KanbanBoard user={user} tasks={tasks} onTasksChange={onTasksChange} />
          </StyledPaper>
        </Grid>
      </Grid>
    </Root>
  );
};

export default Dashboard;
