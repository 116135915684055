import React from 'react';
import { Droppable } from '@hello-pangea/dnd';
import { Box, Typography, IconButton, styled } from '@mui/material';
import TaskCard from './TaskCard';
import AddIcon from '@mui/icons-material/Add';

const ColumnContainer = styled(Box)(({ theme }) => ({
  background: theme.palette.background.paper,
  borderRadius: theme.shape.borderRadius,
  height: '100%',
  display: 'flex',
  flexDirection: 'column',
  overflow: 'hidden'
}));

const ColumnHeader = styled(Box)(({ theme }) => ({
  padding: theme.spacing(2),
  fontWeight: 'bold',
  background: theme.palette.grey[100],
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  borderTopLeftRadius: theme.shape.borderRadius,
  borderTopRightRadius: theme.shape.borderRadius
}));

const TaskList = styled(Box, {
  shouldForwardProp: prop => prop !== 'isDraggingOver'
})(({ theme, isDraggingOver }) => ({
  padding: theme.spacing(1),
  flexGrow: 1,
  minHeight: '100px',
  overflowY: 'auto',
  background: isDraggingOver ? theme.palette.action.hover : 'inherit',
  '& > * + *': {
    marginTop: theme.spacing(1)
  }
}));

const TaskColumn = ({ 
  title, 
  tasks = [], 
  status, 
  isDragDisabled,
  onTaskCreate,
  onTaskUpdate,
  onTaskDelete,
  onTaskClick,
  activeTaskId
}) => {
  const handleAddTask = () => {
    const newTask = {
      title: 'New Task',
      content: 'New Task',
      description: '',
      status: status,
      pomodoro: 1,
      order: tasks.length > 0 ? Math.max(...tasks.map(t => t.order || 0)) + 1 : 0
    };
    onTaskCreate(newTask);
  };

  return (
    <ColumnContainer>
      <ColumnHeader>
        <Typography variant="h6">{title}</Typography>
        <IconButton size="small" onClick={handleAddTask}>
          <AddIcon />
        </IconButton>
      </ColumnHeader>
      <Droppable droppableId={status}>
        {(provided, snapshot) => (
          <TaskList
            ref={provided.innerRef}
            isDraggingOver={snapshot.isDraggingOver}
            {...provided.droppableProps}
          >
            {tasks.map((task, index) => (
              <TaskCard
                key={task.id}
                task={task}
                index={index}
                isDragDisabled={isDragDisabled}
                onUpdate={onTaskUpdate}
                onDelete={onTaskDelete}
                onClick={() => onTaskClick(task)}
                isActive={activeTaskId === task.id}
              />
            ))}
            {provided.placeholder}
          </TaskList>
        )}
      </Droppable>
    </ColumnContainer>
  );
};

export default React.memo(TaskColumn);
