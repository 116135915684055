const getApiUrl = () => {
  if (process.env.REACT_APP_API_URL) {
    return process.env.REACT_APP_API_URL;
  }
  return process.env.NODE_ENV === 'production'
    ? 'https://flowmaster-yvzs.onrender.com'
    : 'http://localhost:5000';  // Remove /api/ from base URL
};

export const API_URL = getApiUrl();
