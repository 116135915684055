import React, { useState, useEffect } from 'react';
import { ThemeProvider } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import { AppBar, Toolbar, IconButton, Tooltip, Box, Divider, CircularProgress } from '@mui/material';
import ContactMailIcon from '@mui/icons-material/ContactMail';
import theme from './theme';
import KanbanBoard from './components/KanbanBoard/KanbanBoard';
import StatisticsBanner from './components/StatisticsBanner';
import FeedbackDialog from './components/FeedbackDialog';
import AuthButtons from './components/Auth/AuthButtons';
import UserProfile from './components/Auth/UserProfile';
import { onAuthChange } from './services/authService';
import { getTasks } from './services/taskService';
import { Routes, Route, BrowserRouter } from 'react-router-dom';
import Container from '@mui/material/Container';
import Dashboard from './pages/Dashboard'; // Import the Dashboard component

function App() {
  const [feedbackOpen, setFeedbackOpen] = useState(false);
  const [completedPomodoros, setCompletedPomodoros] = useState(0);
  const [user, setUser] = useState(null);
  const [tasks, setTasks] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const unsubscribe = onAuthChange((user) => {
      setUser(user);
      if (user) {
        // Load user's tasks when they sign in with retry logic
        loadUserTasks(user.uid);
      } else {
        // Clear tasks when user signs out
        setTasks([]);
        setLoading(false);
        setError(null);
      }
    });

    return () => unsubscribe();
  }, []);

  const loadUserTasks = async (userId, retryCount = 0) => {
    const maxRetries = 3;
    const retryDelay = 1000; // 1 second

    try {
      setLoading(true);
      setError(null);
      console.log(`Attempting to load tasks for user ${userId}, attempt ${retryCount + 1}`);
      
      const userTasks = await getTasks(userId);
      console.log('Successfully loaded tasks:', userTasks);
      setTasks(userTasks);
      setLoading(false);
    } catch (error) {
      console.error('Error loading tasks:', error);
      
      if (retryCount < maxRetries) {
        console.log(`Retrying in ${retryDelay}ms...`);
        setTimeout(() => {
          loadUserTasks(userId, retryCount + 1);
        }, retryDelay);
      } else {
        setError('Failed to load tasks. Please try refreshing the page.');
        setLoading(false);
      }
    }
  };

  const handleOpenFeedback = () => {
    setFeedbackOpen(true);
  };

  const handleCloseFeedback = () => {
    setFeedbackOpen(false);
  };

  const handleTasksChange = (newTasks) => {
    setTasks(newTasks);
  };

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <BrowserRouter>
        <Box sx={{ display: 'flex', flexDirection: 'column', minHeight: '100vh' }}>
          <AppBar position="static" color="primary">
            <Toolbar>
              <Box sx={{ flexGrow: 1 }}>
                {user && <UserProfile user={user} />}
              </Box>
              <AuthButtons user={user} />
              <Tooltip title="Send Feedback">
                <IconButton color="inherit" onClick={handleOpenFeedback}>
                  <ContactMailIcon />
                </IconButton>
              </Tooltip>
            </Toolbar>
          </AppBar>

          <Container maxWidth="xl" sx={{ flexGrow: 1, py: 3 }}>
            <StatisticsBanner completedPomodoros={completedPomodoros} />
            <Divider sx={{ my: 2 }} />
            
            {loading ? (
              <Box sx={{ display: 'flex', justifyContent: 'center', py: 3 }}>
                <CircularProgress />
              </Box>
            ) : error ? (
              <div>{error}</div>
            ) : (
              <Routes>
                <Route 
                  path="/" 
                  element={
                    <Dashboard 
                      user={user}
                      tasks={tasks}
                      onTasksChange={handleTasksChange}
                    />
                  }
                />
              </Routes>
            )}
          </Container>

          <FeedbackDialog open={feedbackOpen} onClose={handleCloseFeedback} />
        </Box>
      </BrowserRouter>
    </ThemeProvider>
  );
}

export default App;
