import React, { useState } from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField,
  Button,
  Snackbar,
  Alert,
} from '@mui/material';
import emailjs from '@emailjs/browser';

// Initialize EmailJS with public key
emailjs.init("t5RVtm5s2iG2D2aVX");

const FeedbackDialog = ({ open, onClose }) => {
  const [feedback, setFeedback] = useState('');
  const [email, setEmail] = useState('');
  const [name, setName] = useState('');
  const [snackbar, setSnackbar] = useState({ open: false, message: '', severity: 'success' });

  const handleSubmit = async (e) => {
    e.preventDefault();
    
    try {
      const templateParams = {
        to_name: "Andrew WP Acevedo",
        from_name: name,
        reply_to: email,
        message: feedback,
      };

      console.log('Sending email with params:', templateParams);
      
      const result = await emailjs.send(
        'service_c8k0yyk',
        'template_0ma096v',
        templateParams,
        't5RVtm5s2iG2D2aVX'
      );

      console.log('Email sent successfully:', result);
      setSnackbar({
        open: true,
        message: 'Feedback sent successfully!',
        severity: 'success',
      });
      setFeedback('');
      setEmail('');
      setName('');
      onClose();
    } catch (error) {
      console.error('Failed to send email:', error);
      setSnackbar({
        open: true,
        message: 'Failed to send feedback. Please try again.',
        severity: 'error',
      });
    }
  };

  const handleCloseSnackbar = () => {
    setSnackbar({ ...snackbar, open: false });
  };

  return (
    <>
      <Dialog open={open} onClose={onClose} maxWidth="sm" fullWidth>
        <DialogTitle>Send Feedback</DialogTitle>
        <form onSubmit={handleSubmit}>
          <DialogContent>
            <TextField
              autoFocus
              margin="dense"
              label="Name"
              type="text"
              fullWidth
              value={name}
              onChange={(e) => setName(e.target.value)}
              required
              variant="outlined"
            />
            <TextField
              margin="dense"
              label="Email"
              type="email"
              fullWidth
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              required
              variant="outlined"
            />
            <TextField
              margin="dense"
              label="Feedback"
              multiline
              rows={4}
              fullWidth
              value={feedback}
              onChange={(e) => setFeedback(e.target.value)}
              required
              variant="outlined"
            />
          </DialogContent>
          <DialogActions>
            <Button onClick={onClose} color="primary">
              Cancel
            </Button>
            <Button type="submit" variant="contained" color="primary">
              Send Feedback
            </Button>
          </DialogActions>
        </form>
      </Dialog>
      <Snackbar
        open={snackbar.open}
        autoHideDuration={6000}
        onClose={handleCloseSnackbar}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
      >
        <Alert
          onClose={handleCloseSnackbar}
          severity={snackbar.severity}
          variant="filled"
        >
          {snackbar.message}
        </Alert>
      </Snackbar>
    </>
  );
};

export default FeedbackDialog;
