import React from 'react';

const TomatoIcon = ({ size = 24, color = '#ff6347' }) => {
  const isGrayedOut = color === '#ddd';
  
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      {/* Tomato body */}
      <path
        d="M12 6C7.58172 6 4 9.13401 4 13C4 16.866 7.58172 20 12 20C16.4183 20 20 16.866 20 13C20 9.13401 16.4183 6 12 6Z"
        fill={color}
      />
      {/* Stem */}
      <path
        d="M12 6C12 6 13 3 12 2"
        stroke={isGrayedOut ? '#aaa' : '#2d5a27'}
        strokeWidth="1.5"
        strokeLinecap="round"
      />
      {/* Leaves */}
      <path
        d="M12 5C12 5 10 3 8 4"
        stroke={isGrayedOut ? '#aaa' : '#2d5a27'}
        strokeWidth="1.5"
        strokeLinecap="round"
      />
      <path
        d="M12 5C12 5 14 3 16 4"
        stroke={isGrayedOut ? '#aaa' : '#2d5a27'}
        strokeWidth="1.5"
        strokeLinecap="round"
      />
      {/* Highlight */}
      <path
        d="M9 11C9.5 10.5 11 10 11.5 11"
        stroke={isGrayedOut ? '#eee' : '#ff8c7a'}
        strokeWidth="1.5"
        strokeLinecap="round"
      />
    </svg>
  );
};

export default TomatoIcon;
