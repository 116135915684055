import React, { useEffect } from 'react';
import { Button, Box } from '@mui/material';
import GoogleIcon from '@mui/icons-material/Google';
import { signInWithGoogle, signOutUser, handleRedirectResult } from '../../services/authService';

const AuthButtons = ({ user, onSignIn, onSignOut }) => {
  useEffect(() => {
    // Check for redirect result when component mounts
    handleRedirectResult().then(user => {
      if (user) {
        onSignIn?.(user);
      }
    }).catch(error => {
      console.error('Redirect result error:', error);
    });
  }, [onSignIn]);

  const handleSignIn = async () => {
    try {
      await signInWithGoogle();
      // The actual sign-in will be handled in the useEffect hook after redirect
    } catch (error) {
      console.error('Sign in error:', error);
    }
  };

  const handleSignOut = async () => {
    try {
      await signOutUser();
      onSignOut?.();
    } catch (error) {
      console.error('Sign out error:', error);
    }
  };

  return (
    <Box sx={{ display: 'flex', gap: 1 }}>
      {!user ? (
        <Button
          variant="contained"
          color="primary"
          startIcon={<GoogleIcon />}
          onClick={handleSignIn}
          size="small"
        >
          Sign In
        </Button>
      ) : (
        <Button
          variant="outlined"
          color="primary"
          onClick={handleSignOut}
          size="small"
        >
          Sign Out
        </Button>
      )}
    </Box>
  );
};

export default AuthButtons;
