import React, { useState, useEffect } from 'react';
import { styled } from '@mui/material/styles';
import {
  Paper,
  Typography,
  Button,
  IconButton,
  CircularProgress,
  Box,
  Stack,
  LinearProgress,
} from '@mui/material';
import PlayArrowIcon from '@mui/icons-material/PlayArrow';
import PauseIcon from '@mui/icons-material/Pause';
import StopIcon from '@mui/icons-material/Stop';
import { createPomodoroEvent } from '../../services/pomodoroService';
import { updateTaskPomodoro } from '../../services/taskPomodoroService';
import { getCurrentUser } from '../../services/authService';
import TomatoIcon from './TomatoIcon';

const Root = styled(Paper)(({ theme }) => ({
  padding: theme.spacing(3),
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  gap: theme.spacing(2),
  backgroundColor: '#fff5f5',
}));

const TimerDisplay = styled('div')(({ theme }) => ({
  position: 'relative',
  display: 'inline-flex',
  marginBottom: theme.spacing(2),
}));

const TimeText = styled(Typography)(({ theme }) => ({
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
}));

const PomodoroTimer = ({ task }) => {
  const [timeLeft, setTimeLeft] = useState(25 * 60); // 25 minutes in seconds
  const [isRunning, setIsRunning] = useState(false);
  const [isBreak, setIsBreak] = useState(false);
  const [completedPomodoros, setCompletedPomodoros] = useState(task?.completedPomodoros || 0);
  const [sessionStartTime, setSessionStartTime] = useState(null);
  const [progress, setProgress] = useState(100);

  useEffect(() => {
    if (task) {
      setCompletedPomodoros(task.completedPomodoros);
    }
  }, [task]);

  useEffect(() => {
    let timer;
    if (isRunning && timeLeft > 0) {
      if (!sessionStartTime) {
        setSessionStartTime(new Date());
      }
      timer = setInterval(() => {
        setTimeLeft((prevTime) => {
          const newTime = prevTime - 1;
          setProgress((newTime / (isBreak ? 5 * 60 : 25 * 60)) * 100);
          return newTime;
        });
      }, 1000);
    } else if (timeLeft === 0) {
      handleTimerComplete();
    }
    return () => clearInterval(timer);
  }, [isRunning, timeLeft, isBreak, sessionStartTime]);

  const handleTimerComplete = async () => {
    if (!isBreak) {
      // Save pomodoro event to MongoDB
      const user = getCurrentUser();
      if (user) {
        try {
          // Create pomodoro event
          await createPomodoroEvent({
            userId: user.uid,
            taskId: task?._id,
            startTime: sessionStartTime,
            endTime: new Date(),
            duration: 25, // minutes
            completed: true
          });

          // Update task's completed pomodoros if task exists
          if (task?._id) {
            await updateTaskPomodoro(task._id);
            setCompletedPomodoros((prev) => prev + 1);
          }

          console.log('Pomodoro event saved successfully');
        } catch (error) {
          console.error('Error saving pomodoro event:', error);
        }
      }

      setIsBreak(true);
      setTimeLeft(5 * 60);
    } else {
      setIsBreak(false);
      setTimeLeft(25 * 60);
    }
    setIsRunning(false);
    setSessionStartTime(null);
  };

  const handleStartStop = () => {
    if (!isRunning) {
      setSessionStartTime(new Date());
    }
    setIsRunning((prev) => !prev);
  };

  const handleReset = () => {
    setIsRunning(false);
    setTimeLeft(isBreak ? 5 * 60 : 25 * 60);
    setSessionStartTime(null);
  };

  const formatTime = (seconds) => {
    const minutes = Math.floor(seconds / 60);
    const remainingSeconds = seconds % 60;
    return `${minutes.toString().padStart(2, '0')}:${remainingSeconds.toString().padStart(2, '0')}`;
  };

  return (
    <Root elevation={3}>
      <Typography variant="h6" gutterBottom>
        {isBreak ? 'Break Time' : 'Focus Time'}
        {task && (
          <Typography variant="subtitle1" color="textSecondary">
            {task.title}
          </Typography>
        )}
      </Typography>
      
      {task && (
        <Box width="100%" mb={2}>
          <LinearProgress
            variant="determinate"
            value={(completedPomodoros / task.pomodoro) * 100}
          />
          <Typography variant="caption" color="textSecondary" align="center" display="block">
            {completedPomodoros} / {task.pomodoro} Pomodoros
          </Typography>
        </Box>
      )}

      <TimerDisplay>
        <CircularProgress
          variant="determinate"
          value={100}
          size={120}
          thickness={4}
          style={{ color: '#eee' }}
        />
        <CircularProgress
          variant="determinate"
          value={progress}
          size={120}
          thickness={4}
        />
        <TimeText variant="h4">
          {formatTime(timeLeft)}
        </TimeText>
      </TimerDisplay>

      <Stack direction="row" spacing={1}>
        <IconButton 
          onClick={handleStartStop}
          color="primary"
          size="large"
        >
          {isRunning ? <PauseIcon /> : <PlayArrowIcon />}
        </IconButton>
        <IconButton
          onClick={handleReset}
          color="secondary"
          size="large"
        >
          <StopIcon />
        </IconButton>
      </Stack>

      <Box display="flex" gap={1} marginTop={2}>
        {task ? (
          [...Array(task.pomodoro)].map((_, index) => (
            <Box key={index} display="inline-flex" alignItems="center" mx={0.5}>
              <TomatoIcon
                size={28}
                color={index < completedPomodoros ? '#ff6347' : '#ddd'}
              />
            </Box>
          ))
        ) : (
          [...Array(completedPomodoros)].map((_, index) => (
            <Box key={index} display="inline-flex" alignItems="center" mx={0.5}>
              <TomatoIcon size={28} />
            </Box>
          ))
        )}
      </Box>
    </Root>
  );
};

export default PomodoroTimer;
