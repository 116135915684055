import React, { useState, useEffect } from 'react';
import { Draggable } from '@hello-pangea/dnd';
import { 
  Card, 
  CardContent, 
  Typography, 
  IconButton,
  TextField,
  Box,
  LinearProgress,
  styled,
  Tooltip,
  Stack
} from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import SaveIcon from '@mui/icons-material/Save';
import CancelIcon from '@mui/icons-material/Cancel';
import TimerIcon from '@mui/icons-material/Timer';
import TomatoIcon from '@mui/icons-material/Brightness1'; // Using a circle icon as tomato

const StyledCard = styled(Card, {
  shouldForwardProp: prop => !['isActive', 'isDragging'].includes(prop)
})(({ theme, isActive, isDragging }) => ({
  marginBottom: theme.spacing(1),
  cursor: 'pointer',
  backgroundColor: isActive ? theme.palette.primary.light : isDragging ? theme.palette.grey[50] : theme.palette.background.paper,
  borderColor: isActive ? theme.palette.primary.main : 'transparent',
  borderWidth: 1,
  borderStyle: 'solid',
  '&:hover': {
    boxShadow: theme.shadows[2],
    borderColor: isActive ? theme.palette.primary.main : theme.palette.grey[300]
  }
}));

const CompletedTomato = styled(TomatoIcon)(({ theme }) => ({
  color: '#e53935', // Red color for completed tomatoes
  fontSize: '1.2rem',
  filter: 'brightness(1)',
  transition: 'all 0.2s ease-in-out',
  '&:hover': {
    filter: 'brightness(0.9)'
  }
}));

const IncompleteTomato = styled(TomatoIcon)(({ theme }) => ({
  color: '#ffcdd2', // Light red color for incomplete tomatoes
  fontSize: '1.2rem',
  opacity: 0.7,
  transition: 'all 0.2s ease-in-out',
  '&:hover': {
    opacity: 0.9
  }
}));

const TaskCard = ({ 
  task, 
  index, 
  onUpdate, 
  onDelete, 
  isDragDisabled,
  onClick,
  isActive,
  onPomodoroComplete
}) => {
  const [isEditing, setIsEditing] = useState(false);
  const [editedTitle, setEditedTitle] = useState('');
  const [editedDescription, setEditedDescription] = useState('');
  const [editedPomodoros, setEditedPomodoros] = useState(1);
  const [pomodoroRunning, setPomodoroRunning] = useState(false);
  const [pomodoroProgress, setPomodoroProgress] = useState(0);

  useEffect(() => {
    if (task) {
      setEditedTitle(task.title);
      setEditedDescription(task.description || '');
      setEditedPomodoros(task.pomodoro || 1);
    }
  }, [task]);

  const handleSave = () => {
    if (!editedTitle.trim()) return;
    
    onUpdate(task.id, {
      title: editedTitle.trim(),
      description: editedDescription.trim(),
      pomodoro: Math.min(Math.max(1, editedPomodoros), 10),
      status: task.status
    });
    setIsEditing(false);
  };

  const handleCancel = () => {
    setEditedTitle(task.title);
    setEditedDescription(task.description || '');
    setEditedPomodoros(task.pomodoro || 1);
    setIsEditing(false);
  };

  const handleCardClick = () => {
    if (onClick) onClick(task.id);
  };

  const handleDelete = (e) => {
    e.stopPropagation();
    onDelete(task.id);
  };

  const startPomodoro = (e) => {
    e.stopPropagation();
    if (pomodoroRunning) return;
    
    setPomodoroRunning(true);
    setPomodoroProgress(0);
    
    const duration = 25 * 60; // 25 minutes in seconds
    const startTime = Date.now();
    
    const timer = setInterval(() => {
      const elapsed = (Date.now() - startTime) / 1000;
      const progress = (elapsed / duration) * 100;
      
      if (progress >= 100) {
        clearInterval(timer);
        setPomodoroRunning(false);
        setPomodoroProgress(0);
        onPomodoroComplete?.();
      } else {
        setPomodoroProgress(progress);
      }
    }, 1000);

    return () => clearInterval(timer);
  };

  const renderEditMode = () => (
    <CardContent>
      <TextField
        fullWidth
        value={editedTitle}
        onChange={(e) => setEditedTitle(e.target.value)}
        margin="dense"
        label="Title"
      />
      <TextField
        fullWidth
        value={editedDescription}
        onChange={(e) => setEditedDescription(e.target.value)}
        margin="dense"
        label="Description"
        multiline
        rows={2}
      />
      <TextField
        type="number"
        value={editedPomodoros}
        onChange={(e) => setEditedPomodoros(Math.min(Math.max(1, parseInt(e.target.value) || 1), 10))}
        margin="dense"
        label="Pomodoros"
        inputProps={{ min: 1, max: 10 }}
      />
      <Box sx={{ mt: 1, display: 'flex', justifyContent: 'flex-end', gap: 1 }}>
        <IconButton size="small" onClick={handleCancel}>
          <CancelIcon />
        </IconButton>
        <IconButton size="small" onClick={handleSave}>
          <SaveIcon />
        </IconButton>
      </Box>
    </CardContent>
  );

  const renderViewMode = () => (
    <CardContent>
      <Box display="flex" justifyContent="space-between" alignItems="flex-start">
        <Typography variant="h6" component="div" sx={{ flexGrow: 1 }}>
          {task.title}
        </Typography>
        <Box>
          <Stack direction="row" spacing={0.5} sx={{ flexGrow: 1 }}>
            {Array.from({ length: task.pomodoro || 1 }).map((_, index) => (
              <Tooltip 
                key={index} 
                title={index < (task.completedPomodoros || 0) ? "Completed" : "Not completed"}
              >
                {index < (task.completedPomodoros || 0) ? (
                  <CompletedTomato />
                ) : (
                  <IncompleteTomato />
                )}
              </Tooltip>
            ))}
          </Stack>
        </Box>
      </Box>
      {task.description && (
        <Typography variant="body2" color="text.secondary" sx={{ mt: 1 }}>
          {task.description}
        </Typography>
      )}
      <Box sx={{ mt: 1, display: 'flex', justifyContent: 'flex-end', gap: 1 }}>
        <Tooltip title="Start 25min Pomodoro">
          <IconButton 
            size="small" 
            onClick={startPomodoro}
            disabled={pomodoroRunning}
            color={pomodoroRunning ? "primary" : "default"}
          >
            <TimerIcon />
          </IconButton>
        </Tooltip>
        <IconButton 
          size="small" 
          onClick={(e) => {
            e.stopPropagation();
            setIsEditing(true);
          }}
        >
          <EditIcon />
        </IconButton>
        <IconButton 
          size="small" 
          onClick={handleDelete}
        >
          <DeleteIcon />
        </IconButton>
      </Box>
      {pomodoroRunning && (
        <LinearProgress 
          variant="determinate" 
          value={pomodoroProgress} 
          sx={{ mt: 1 }}
        />
      )}
    </CardContent>
  );

  return (
    <Draggable
      draggableId={task.id}
      index={index}
      isDragDisabled={isDragDisabled || isEditing}
    >
      {(provided, snapshot) => (
        <div
          ref={provided.innerRef}
          {...provided.draggableProps}
          {...provided.dragHandleProps}
        >
          <StyledCard 
            isActive={isActive}
            isDragging={snapshot.isDragging}
            onClick={handleCardClick}
          >
            {isEditing ? renderEditMode() : renderViewMode()}
          </StyledCard>
        </div>
      )}
    </Draggable>
  );
};

export default React.memo(TaskCard);
