import { API_URL } from '../config/api';

export const getPomodoroEvents = async (userId) => {
  try {
    const response = await fetch(`${API_URL}/pomodoros/${userId}`);
    if (!response.ok) throw new Error('Failed to fetch pomodoro events');
    return await response.json();
  } catch (error) {
    console.error('Error fetching pomodoro events:', error);
    throw error;
  }
};

export const createPomodoroEvent = async (eventData) => {
  try {
    const response = await fetch(`${API_URL}/pomodoros`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(eventData),
    });
    if (!response.ok) throw new Error('Failed to create pomodoro event');
    return await response.json();
  } catch (error) {
    console.error('Error creating pomodoro event:', error);
    throw error;
  }
};

export const getPomodoroStats = async (userId) => {
  try {
    const response = await fetch(`${API_URL}/pomodoros/stats/${userId}`);
    if (!response.ok) throw new Error('Failed to fetch pomodoro stats');
    return await response.json();
  } catch (error) {
    console.error('Error fetching pomodoro stats:', error);
    throw error;
  }
};
