import axios from 'axios';
import { API_URL } from '../config/api';

export const updateTaskPomodoro = async (taskId) => {
  try {
    const response = await axios.post(`${API_URL}/api/tasks/${taskId}/pomodoro`);
    return response.data;
  } catch (error) {
    console.error('Error updating task pomodoro:', error);
    throw error;
  }
}
