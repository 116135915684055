import { initializeApp } from 'firebase/app';
import { 
  getAuth, 
  GoogleAuthProvider, 
  signInWithRedirect,
  getRedirectResult,
  signOut,
  onAuthStateChanged
} from 'firebase/auth';
import { API_URL } from '../config/api';

const firebaseConfig = {
  apiKey: process.env.REACT_APP_FIREBASE_API_KEY || "AIzaSyAg47yqK974IjvtnsqX13DbGD2lznCNhI4",
  authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN || "kanbanheroquest-b2df3.firebaseapp.com",
  projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID || "kanbanheroquest-b2df3",
  storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET || "kanbanheroquest-b2df3.appspot.com",
  messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID || "1027897042935",
  appId: process.env.REACT_APP_FIREBASE_APP_ID || "1:1027897042935:web:e7b1a9b6f7f7b0b0b0b0b0"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const auth = getAuth(app);
const googleProvider = new GoogleAuthProvider();

// Configure provider settings
googleProvider.setCustomParameters({
  prompt: 'select_account'
});

// Handle redirect result
export const handleRedirectResult = async () => {
  try {
    const result = await getRedirectResult(auth);
    if (result?.user) {
      // Send the user data to our server to store in MongoDB
      const response = await fetch(`${API_URL}/api/auth/register`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          googleId: result.user.uid,
          email: result.user.email,
          displayName: result.user.displayName,
          firstName: result.user.displayName?.split(' ')[0],
          lastName: result.user.displayName?.split(' ')[1] || '',
          profilePicture: result.user.photoURL
        }),
        credentials: 'include'
      });

      if (!response.ok) {
        throw new Error('Failed to register user with server');
      }
      return result.user;
    }
    return null;
  } catch (error) {
    console.error('Error handling redirect result:', error);
    throw error;
  }
};

export const signInWithGoogle = async () => {
  try {
    await signInWithRedirect(auth, googleProvider);
  } catch (error) {
    console.error('Error initiating Google sign-in:', error);
    throw error;
  }
};

export const signOutUser = async () => {
  try {
    await signOut(auth);
  } catch (error) {
    console.error('Error signing out:', error);
    throw error;
  }
};

export const getCurrentUser = () => {
  return auth.currentUser;
};

export const onAuthChange = (callback) => {
  return onAuthStateChanged(auth, callback);
};

export default auth;
